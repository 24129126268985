<template>
  <div class="section" >
    <div class="section-title">
      <div class="title-box">
        <div class="main-title section-header">
          <p>热门图书</p>
        </div>
      </div>
      <div class="more" >资深专家精心编撰 涵盖新大纲主要考点></div>
    </div>
    <!-- 课程列表 -->
    <div class="course-box">
      <div class="course-list">
        <div class="c-list">
            <div v-for="(item,key) in booksList"
            :key="key"
            class="c-item"
            @click="booksshow(key)"
            >
            <div class="c-item-tit" :class="[booksListshow == key ? 'active':'' ]">{{ item.name }}</div>
            <!-- <div v-for="(item,key) in item.list"
            :key="key"
            v-show="booksListshow == 0"
            >
            <div class="middle">
                <div class="book-img">
                <img :src="item.img" alt="">
                </div>
                <div class="book-info">

                </div>
            </div> -->
            <!-- <div class="foot">
                {{ item.tit }}
                <img :src="item.img" alt="">
                {{ item.price }}
                {{ item.sold }}
            </div> -->
            </div>
            </div>
            <div class="middle" v-show="booksListshow == 0">
                <div class="book-img">
                    <img :src="doctordata.img" alt="">
                </div>
                <div class="book-info">
                    <div class="book-name">{{ doctordata.name }}</div>
                    <div class="book-price">现价：<span>¥ </span><span class="amount">{{ doctordata.price }}</span></div>
                    <div class="book-sold">已售：<span>{{ doctordata.sold }}</span>本</div>
                    <div class="info-bottom">
                      <div class="service-promise">服务承诺:</div>
                      <div class="promise-list">
                        <div class="quality">正品保证</div>
                        <div class="fast">极速发货</div>
                        <div class="refund">七天无理由退货</div>
                      </div>
                      <div class="button-group">立即购买</div>
                    </div>
                </div>
            </div>
            <ul class="book-list" v-show="booksListshow == 0">
                <li v-for="(item,key) in doctorlist"
                :key="key"
                class="book-item"
                @click="bookItem(key)"
                >
            <img :src="item.img" alt="" class="book-item-img"></img>
            <p class="book-item-name">{{ item.name }}</p>
        </li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseApi } from '@/api/course';
import CourseClassCard from '@/pages/course/components/course-class-card';
export default {
  name: 'RecommendCourseList',
  components: {
    CourseClassCard
  },
  props: {},
  data () {
    return {
      booksListshow: 1,
      booksList: [
        { name:'医师资格'},
        { name:'药师资格'},
        { name:'护士资格'},
        { name:'初级职称'},
        { name:'中级职称'},
        { name:'成人高考'},
        { name:'健康管理'},
        { name:'对口升学'},
        { name:'其他'}
      ],
      doctorlist:[
        {name:'医路无忧拍纸本1',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本2',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本3',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本4',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本5',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本6',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'},
        {name:'医路无忧拍纸本7',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'}
    ],
    doctordata:{name:'医路无忧拍纸本',price:12.5,sold:1665,img:'https://renminyixue-prod.oss-cn-qingdao.aliyuncs.com/pu/goods/7dce0c3871754beb82a5676d4d47e46d23112509.jpg'}
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
    this.queryRecomendList();
  },
  methods: {
    // 切换购买课程
    booksshow (e) {
      console.log(e)
      this.booksListshow = e
    },
    bookItem(e){
      this.doctordata = this.doctorlist[e]
    },
    // 查询热门课程
    async queryRecomendList () {
      try {
        this.$Spin.show();
        let param = {
          pageNum: 1,
          pageSize: 15
        };
        const { data } = await courseApi.queryList(param);
        this.courseList = data.list;
      } catch (e) {
        console.log(e);
      } finally {
        this.$Spin.hide();
        console.log(this.courseList)
      }
    },
    // 跳转课程列表
    toCourseList () {
      this.$router.push({
        path: '/course'
      });
    },
    onDetail (item) {
      this.$router.push({
        path: `/course/detail?courseType=${item.courseType}&courseId=${item.courseId}`
      });
    },
  }
};
</script>
<style lang='less' scoped>
  @import '../section.less';
  .section{
    margin-bottom: 0px;
  }
  .section-title{
    margin-top: 30px;
  }
  .course-box {
    width: 100%;

    .course-list {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 19px;
      border-radius: 4px;

      .c-list {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        padding-bottom:10px ;
        .c-item {
          margin-left: 14px;
          margin-top: 15px;

          &:nth-child(5n + 1) {
            margin-left: 12px;
          }
          .c-item-tit{
            padding: 0 8px;
            height: 40px;
            line-height: 38px;
            border: 1px solid #ededed;
            border-radius: 4px;
            margin-right: 20px;
            color: #666;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
          }
          .active{
            line-height: 40px;
            background: linear-gradient(312deg, #DFF0FF 6%, #1462ED 95%);
            border: none;
            color: #fff;
          }
        }
      }
      .middle{
                display: flex;
                background: #fff;
                .book-img{
                    display: flex;
                    padding: 24px;

                    img{
                    display: block;
                    width: 224px;
                    height: 224px;
                    margin: auto;
                    }
                }
                .book-info{
                    flex: 1;
                    overflow: hidden;
                    padding: 36px 24px 21px 28px;
                    border-left: 1px solid #ededed;
                    display: flex;
                    flex-direction: column;
                    // justify-content: flex-end;
                    .book-name{
                        line-height: 31px;
                        font-size: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-bottom: 10px;
                    }
                    .book-price{
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #999;
                        margin-bottom: 10px;
                        .amount{
                          display: inline-block;
                          padding-bottom: 10px;
                          font-size: 24px;
                          font-weight: 700;
                        }
                        span{
                          color:#ff572a;
                        }
                    }
                    .book-sold{
                        font-size: 12px;
                        color: #999;
                        margin-bottom: 10px;
                        span{
                          display: inline-block;
                          padding-right: 5px;
                          color: black;
                        }
                    }
                    .info-bottom{
                      display: flex;
                      align-items: flex-end;
                      .service-promise{
                        font-size: 12px;
                        color: #999;
                        margin-bottom: 10px;
                      }
                      .promise-list{
                        margin-left: 20px;
                        margin-bottom: 10px;
                        display: flex;
                        flex: 1;
                        div{
                          margin-right: 12px;
                          padding-left: 20px;
                          height: 14px;
                          line-height: 14px;
                          font-size: 12px;
                          color: #666;
                          background-position: left center;
                          background-repeat: no-repeat;
                        }
                        .quality{
                          // 没有icon
                        }
                        .fast{
                          // 没有icon
                        }
                        .refund{
                          // 没有icon
                        }
                      }
                      .button-group{
                        width: 182px;
                        text-align: center;
                        height: 60px;
                        line-height: 58px;
                        color: #fff;
                        border: 1px solid #DFF0FF;
                        background: linear-gradient(312deg, #DFF0FF 6%, #1462ED 95%);
                        border-right: none;
                        border-radius: 4px;
                      }
                    }
                }
            }
            .book-list{
                background: #fff;
                height: 156px;
                display: flex;
                align-items: center;
                margin: 0 -14px;
                .book-item{
                    padding: 20px 0;
                    width: 144px;
                    height: 156px;
                    margin: 10px 14px;
                    cursor: pointer;

                    .book-item-img{
                        display: block;
                        width: 105px;
                        height: 105px;
                        margin: 0 auto;
                    }
                    .book-item-name{
                        text-align: center;
                        margin-top: 8px;
                        font-size: 12px;
                        line-height: 18px;
                        color: #999;
                    }
                }
            }
    }
  }
</style>
