// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/index/all-major.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/image/common/recommend-tag.gif");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header[data-v-17e069f7]{background-color:#fff}.header .header-container[data-v-17e069f7]{margin:0 auto;width:1200px}.header .header-container .menu[data-v-17e069f7]{display:flex;height:46px}.header .header-container .menu .major[data-v-17e069f7]{background-image:linear-gradient(90deg,#00d887,#00a7d2);border-radius:8px 8px 0 0;width:232px}.header .header-container .menu .major .all-major[data-v-17e069f7]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 15px no-repeat;color:#fff;font-size:15px;font-weight:700;height:46px;line-height:46px;padding-left:44px}.header .header-container .menu .menu-nav[data-v-17e069f7]{align-items:center;display:flex;flex:1;height:46px;justify-content:space-between;overflow:hidden;padding-left:30px}.header .header-container .menu .menu-nav .nav-item[data-v-17e069f7]{color:#333;font-size:16px;position:relative}.header .header-container .menu .menu-nav .nav-item.recommend[data-v-17e069f7]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:28px 14px;content:\"\";height:14px;position:absolute;right:-25px;top:-11px;width:28px}.header .header-container .menu .menu-nav .nav-item[data-v-17e069f7]:hover{color:#1890ff}.banner[data-v-17e069f7]{position:relative}.main-box[data-v-17e069f7]{background:linear-gradient(180deg,#fff,#f7f7f7);margin:28px auto 0}.main[data-v-17e069f7]{margin:0 auto;width:1200px}.body-bg[data-v-17e069f7]{background:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
