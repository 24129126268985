<template>
  <div class="section" v-show="courseList.length > 0">
    <div class="section-title">
      <div class="title-box">
        <div class="main-title section-header">
          <p>热门课程</p>
        </div>
      </div>
      <div class="more" @click="toCourseList">全部课程></div>
    </div>
    <!-- 课程列表 -->
    <div class="course-box">
      <div class="course-list">
        <div class="c-list">
          <div
            v-for="(item,key) in courseList"

            :key="key"
            class="c-item"
            @click="onDetail(item)"
          >
            <CourseClassCard :course-info="item"/>
          </div>
          <div v-show="courseList.length === 0" style="width: 100%;height: 120px;text-align: center">
            <span style="font-size: 18px;margin-top: 50px;display: block;">未搜索到相关课程推荐，请您看看其他分类吧：）</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseApi } from '@/api/course';
import CourseClassCard from '@/pages/course/components/course-class-card';
export default {
  name: 'RecommendCourseList',
  components: {
    CourseClassCard
  },
  props: {},
  data () {
    return {
      courseList: []
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
    this.queryRecomendList();
  },
  methods: {
    // 查询热门课程
    async queryRecomendList () {
      try {
        this.$Spin.show();
        let param = {
          pageNum: 1,
          pageSize: 10
        };
        const { data } = await courseApi.queryList(param);
        this.courseList = data.list;
      } catch (e) {
        console.log(e);
      } finally {
        this.$Spin.hide();
      }
    },
    // 跳转课程列表
    toCourseList () {
      this.$router.push({
        path: '/course'
      });
    },
    onDetail (item) {
      this.$router.push({
        path: `/course/detail?courseType=${item.courseType}&courseId=${item.courseId}`
      });
    },
  }
};
</script>
<style lang='less' scoped>
  @import '../section.less';
  .section{
    width: 1200px;
    margin: auto;
  }
  .section-title{
    margin-top: 30px;
  }
  .course-box {
    width: 100%;

    .course-list {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 19px;
      border-radius: 4px;

      .c-list {
        display: flex;
        flex-wrap: wrap;

        .c-item {
          margin-left: 14px;
          margin-top: 15px;

          &:nth-child(5n + 1) {
            margin-left: 12px;
          }
        }
      }
    }
  }
</style>
