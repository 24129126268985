<template>
  <div class="section" v-if="teacherList.length > 0">
    <div class="section-title">
      <div class="title-box">
        <div class="main-title section-header">
          <p>名师团队</p>
        </div>
        <div class="sub-title">精选讲师，严格筛选，经验丰富</div>
      </div>
      <div class="more" @click="toTeacherList">全部讲师></div>
    </div>
    <div class="section-voice">
      <TeacherList :teacherList="teacherList"/>
    </div>
  </div>
</template>

<script>
import TeacherList from '@/components/teacher-list';
import { teacherApi } from '@/api/teacher';

export default {
  name: 'TeacherBox',
  components: {
    TeacherList
  },
  props: {},
  data () {
    return {
      teacherList: []
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
    this.queryTeacherList();
  },
  methods: {
    async queryTeacherList () {
      try {
        this.$Spin.show();
        const param = {
          pageNum: 1,
          pageSize: 5
        };
        const { data } = await teacherApi.getLecturerList(param);
        this.teacherList = data.list;
      } catch (e) {
        console.log(e);
      } finally {
        this.$Spin.hide();
      }
    },
    // 跳转讲师列表
    toTeacherList () {
      this.$router.push({
        path: '/teacher'
      });
    }
  }
};
</script>
<style lang='less' scoped>
  @import '../section.less';

  .section {
    width: 1200px;
    margin: auto;
  }
  .section-title{
    margin-top: 30px;
  }
</style>
