<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-20 10:39:11
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-24 16:23:51
-->
<template>
  <div class="body-bg">
    <!-- 首页、全部课程 等 、个人中心 -->
    <MainHeader />

    <IndexBanner/>

    <div class="main-box">
      <div class="main">
        <!-- 热门课程 -->
      </div>
      <div class="main">
        <RecommendCourseList/>
    <LiveSection/>
    <!-- 讲师列表 -->
    <TeacherList/>
    <!-- 学员心声 -->
    <VoiceSection/>


        <!-- 热门书籍 暂时取消等设计 仿人民医学网 -->
        <!-- <RecommendBooksList/> -->
      </div>
      <IndexFooter/>
    </div>
    
  </div>
</template>

<script>
import MainHeader from '@/components/header/main-header';
import userHeader from '@/assets/image/index/user-header.png';
import IndexFooter from '@/components/index-footer';
import { courseApi } from '@/api/course';
import {
  COURSE_SALE_TYPE_ENUM,
  RESOURCE_ATTRIBUTE_ENUM,
  LIVE_PLAYBACK_TYPE_ENUM
} from '@/constant/course';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import { SEARCH_TYPE_ENUM } from '@/constant/common';
import IndexBanner from './index/components/index-banner';
import LiveSection from './index/components/live-section';
import FreeSection from './index/components/free-section';
import CourseSection from './index/components/course-section';
import VoiceSection from './index/components/voice-section';
import TeacherList from './index/components/teacher';
import RecommendCourseList from './index/components/course';
import RecommendBooksList from './index/components/books';
import courseMixin from './course/components/mixins';
import { WEB_PAGE_TDK_PAGE_ENUM } from '@/constant/seo.js';

export default {
  name: 'Index',
  components: {
    MainHeader,
    IndexBanner,
    LiveSection,
    FreeSection,
    CourseSection,
    VoiceSection,
    IndexFooter,
    TeacherList,
    RecommendCourseList,
    RecommendBooksList
  },
  filters: {
    phoneReplace (value) {
      if (!value) {
        return '';
      }
      return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
    }
  },
  mixins: [courseMixin],
  props: {},
  async asyncData ({ store }) {
    // 从store中获取专业列表
    const majorList = store.state.majorList || [];
    return {
      majorList
    };
  },
  head () {
    return this.head;
  },
  data () {
    return {
      head: {},
      SEARCH_TYPE_ENUM,
      showLogin: false,
      searchList: this.$enum.getValueDescList('SEARCH_TYPE_ENUM'),
      searchVisible: false,
      searchType: SEARCH_TYPE_ENUM.COURSE.value,
      searchForm: {
        searchWord: null
      },
      saleType: COURSE_SALE_TYPE_ENUM.COURSE_FRANCO.value,
      userHeader,
      lastCourseRecord: null,
    };
  },
  computed: {
    userInfo () {
      return this.$store.getters['user/userInfo'];
    }
  },
  watch: {
    // 监听用户登录状态改变
    userInfo: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.queryLastCourseRecord();
        } else {
          this.lastCourseRecord = null;
        }
      }
    }
  },
  created () {
  },
  mounted () {
    this.setHeader();
  },
  methods: {
    // 设置header
    async setHeader () {
      this.head = await this.$store.dispatch('seo/getSeoDetail', WEB_PAGE_TDK_PAGE_ENUM.HOME.value);
    },
    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || window.event;
        if (event.keyCode == 13) {
          this.searchQuery();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },

    // 点击登录
    openLogin () {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },

    // 点击注册
    openRegister () {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.REGISTER.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },

    // 跳转上一版PC页面
    openPc (path, query) {
      this.$pcRouter.push({ path, query });
    },

    // 显示搜索下拉框
    onVisibleChange (visible) {
      this.searchVisible = visible;
    },

    // 选择搜索类型
    selectSearchType (type) {
      this.searchType = type;
    },

    // 点击搜索
    searchQuery () {
      const searchWord = this.searchForm.searchWord;
      if (!searchWord) {
        this.$Message.warning('请输入搜索内容');
        return;
      }
      let path;
      if (this.searchType == SEARCH_TYPE_ENUM.COURSE.value) {
        path = '/course';
      } else if (this.searchType == SEARCH_TYPE_ENUM.BOOK.value) {
        path = '/book';
      } else {
        return;
      }
      this.$router.push({
        path,
        query: { searchWord }
      });
    },

    // 查询学员最后一次学习记录
    async queryLastCourseRecord () {
      // const result = await courseApi.queryLastCourseRecord();
      // this.lastCourseRecord = result.data;
    },
    goWatchLastCourse () {
      const {
        courseId,
        courseAttributeType,
        specialCourseId,
        resourceId,
        resourceAttributeType,
        livePlaybackType
      } = this.lastCourseRecord;
      // 如果资源类型为视频，或资源类型为直播但回放类型为视频，跳转到视频播放；否则跳转到直播播放
      if (
        resourceAttributeType === RESOURCE_ATTRIBUTE_ENUM.VIDEO.value ||
        (resourceAttributeType === RESOURCE_ATTRIBUTE_ENUM.LIVE.value &&
          livePlaybackType === LIVE_PLAYBACK_TYPE_ENUM.VIDEO.value)
      ) {
        this.videoGoWatch(
          resourceId,
          resourceAttributeType,
          courseId,
          courseAttributeType,
          specialCourseId
        );
      } else {
        this.liveGoWatch(
          resourceId,
          courseId,
          courseAttributeType,
          specialCourseId
        );
      }
    }
  }
};
</script>
<style lang='less' scoped>
  @import './index/index.less';
</style>
