/*
 * @Description: 教师相关api
 * @Author: hanyu
 * @Date: 2020-09-25 08:39:45
 * @LastEditTime: 2020-09-25 10:25:50
 * @LastEditors: hanyu
 */
import { getAxios, postAxios } from '@/lib/http';
export const teacherApi = {
  // 分页查询讲师列表 by lianmin
  getLecturerList (data) {
    return postAxios('/app/teacher/query', data);
  },
  // 查询讲师详细信息 by lianmin
  getLecturerDetail (teacherId) {
    return getAxios(`/app/teacher/info/${teacherId}`);
  },
  // 查询讲师课程列表 by lianmin
  queryCourseList (teacherId) {
    return getAxios(`/app/teacher/course/${teacherId}`);
  },

};
