import { RESOURCE_ATTRIBUTE_ENUM } from '@/constant/course';
import { commonApi } from '@/api/common';
import { courseApi } from '@/api/course';

export default {
  methods: {
    videoGoWatch (videoCourseResourceId, resourceAttributeType, courseId, courseAttributeType, specialId) {
      this.$pcRouter.push({
        path: 'video-play',
        query: {
          resourceId: videoCourseResourceId,
          specialId,
          courseId,
          courseAttributeType,
          resourceAttributeType
        }
      });
    },
    // 进入保利威视直播
    liveGoWatch (liveCourseResourceId, courseId, courseAttributeType, specialId) {
      this.$pcRouter.push({
        path: 'live-play',
        query: {
          specialId,
          resourceId: liveCourseResourceId,
          courseAttributeType,
          resourceAttributeType: RESOURCE_ATTRIBUTE_ENUM.LIVE.value,
          courseId
        }
      });
    }
  }
};
