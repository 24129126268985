<!--
 * @Description: 通用课程卡片
 * @Author: hanyu
 * @Date: 2020-09-21 18:13:25
 * @LastEditTime: 2020-10-28 17:00:07
 * @LastEditors: hanyu
-->
<template>
  <div class="course-item">
    <!-- 课程类型-->
    <div class="course-type course" v-if="courseInfo.courseType == COURSE_TYPE_ENUM.COURSE.value">
      基础班
    </div>
    <div class="course-type course-series" v-if="courseInfo.courseType == COURSE_TYPE_ENUM.COURSE_SERIES.value">
      套餐班
    </div>
    <div class="item-top">
      <!-- 图片 -->
      <div class="img-div">
        <img v-if="!courseInfo.coverImg || imgError" :src="defaultHeader" class="img-default" />
        <img v-else :src="courseInfo.coverImg" class="img-course" @error="imgError = true" />
      </div>
    </div>
    <div class="item-bottom">
      <!-- 标题 -->
      <div class="item-title one-row">
        {{ courseInfo.courseName }}
      </div>
       <!-- 专业类目 -->
       <div v-if="!lodash.isEmpty(courseInfo.categoryList)" class="item-category one-row">
          {{ courseInfo.categoryList.map(e => e.categoryName).join(",") }}
        </div>
      <!-- 划线价 -->
      <div class="tag-box">
        <div class="line-price">
          <span v-show="courseInfo.crossPrice && courseInfo.crossPrice > 0">
            ¥{{ courseInfo.crossPrice }}
            元
          </span>
        </div>
      </div>
      <!-- 价格 -->
      <div class="price-box">
        <div
            v-if="courseInfo.freeFlag"
            class="price"
        >
          免费
        </div>
        <div v-else class="price">
          <span>¥</span>
          {{ courseInfo.coursePrice }} 元
        </div>
        <div class="price-right">
          <img src="~@/assets/image/common/people.png" class="people-icon">
          <!-- 如果 > 9999 则为 9999+ -->
          {{ courseInfo.enrollment }}人在学习
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { COURSE_TYPE_ENUM } from '@/constant/course';
import defaultHeader from '@/assets/image/index/default-header.png';

export default {
  components: {},
  // 过滤器
  filters: {},
  // 接收参数
  props: {
    courseInfo: Object
  },
  data () {
    // 这里存放数据
    return {
      imgError: false,
      defaultHeader,
      COURSE_TYPE_ENUM
    };
  },
  computed: {},
  watch: {},
  created () {

  },
  mounted () {

  },
  // 方法集合
  methods: {}
};
</script>
<style lang='less' scoped>
.course-item {
  width: 224px;
  border-radius: 4px;
  border: 1px solid #ededed;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    box-shadow: 0px 6px 10px 0px rgba(153, 153, 153, 0.16);

    .item-bottom .item-title {
      color: @main-color;
    }
  }

  .course-type {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 24px;
    border-radius: 0px 0px 8px 0px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    &.course {
      background: #38569c;
      color: #ffffff;
    }

    &.course-series {
      background: linear-gradient(118deg, #fde0b2 7%, #ebba5f 87%);
      color: #614015;
    }
  }

  .item-top {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    height: 126px;
    width: 224px;

    .img-div {
      width: 224px;
      height: 126px;
      overflow: hidden;
      background: #eee;
      text-align: center;

      .img-plan-class-offline {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
      }

      .img-course {
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }

      .img-default {
        display: block;
        height: 126px;
        margin: 0 auto;
      }
    }
  }

  .item-bottom {
    padding: 10px 10px 18px;

    .item-title {
      font-size: 16px;
      color: #333333;
    }
    
    .item-category {
        margin-top: 10px;
        font-size: 12px;
        color: #999999;
    }

    .item-desc {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999999;
      margin-top: 6px;

      .line {
        height: 8px;
        width: 1px;
        background: #999999;
        margin: 0 8px;
      }
    }

    .tag-box {
      margin-top: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line-price {
        height: 15px;
        flex: 1;
        font-size: 12px;
        color: #999999;
        text-decoration: line-through;
      }
    }

    .price-box {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        font-weight: bold;
        color: #ff572a;
        font-size: 18px;

        span {
          font-size: 12px;
        }
      }

      .price-right {
        display: flex;
        align-items: flex-end;
        font-size: 12px;
        color: #999999;

        .people-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
