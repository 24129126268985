<!--
 * @Description: 教师列表
 * @Author: hanyu
 * @Date: 2020-09-22 15:35:02
 * @LastEditTime: 2021-02-04 10:58:28
 * @LastEditors: hanyu
-->
<template>
  <div class="teacher-list-box">
    <div class="teacher-list">
      <div
        v-for="item in teacherList"
        :key="item.lecturerId"
        class="teacher-item"
        @click="onDetail(item)"
      >
        <div class="top-img-box">
          <img v-if="item.teacherImg" :src="item.teacherImg" class="top-img">
        </div>
        <div class="bottom-box">
          <div class="t-title">
            <span class="name">{{ item.teacherName }}</span>
            {{item.tagName?`/${item.tagName}`:''}}
          </div>
          <div class="t-tag one-row">
            {{item.speakContent}}
          </div>
          <div class="t-desc one-row">
            {{ item.teacherBrief }}
          </div>
        </div>
        <!-- hover效果 -->
        <div class="hover-box">
          <div class="h-title">
            {{ item.teacherName }}
          </div>
          <div class="h-project">
            讲授科目:
          </div>
          <div class="h-tag">
            {{item.speakContent}}
          </div>
          <div class="h-desc">
            {{ item.teacherBrief }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'course',
  name: 'Teacher',
  // 接收参数
  props: {
    teacherList: {
      type: Array
    }
  },
  data () {
    return {};
  },
  // 方法集合
  methods: {
    // 详情
    onDetail ({ teacherId }) {
      this.$router.push({
        path: '/teacher/teacher-detail',
        query: {
          teacherId
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>

  .teacher-list-box {
    background: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding: 20px 12px;
    padding-top: 5px;
    border-radius: 4px;

    .teacher-list {
      display: flex;
      flex-wrap: wrap;

      .teacher-item {
        cursor: pointer;
        width: 224px;
        height: 345px;
        margin-left: 14px;
        border-radius: 4px;
        background: #ffffff;
        position: relative;
        overflow: hidden;
        border: 1px solid #ededed;

        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        &:hover .hover-box {
          opacity: 1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .top-img-box {
          background: url('~@/assets/image/common/teacher-bg.png') center no-repeat;
          background-size: 100% 100%;
          width: 224px;
          height: 240px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          border-radius: 4px;

          .top-img {
            width: 265px;
            height: 240px;
            flex-shrink: 0;
            object-fit:cover;
          }
        }

        .bottom-box {
          padding: 12px;
          color: #333333;

          .t-title {
            font-size: 14px;

            .name {
              font-size: 20px;
              font-weight: bolder;
              color: #333333;
              position: relative;

              &::before {
                position: absolute;
                content: '';
                width: 24px;
                height: 2px;
                bottom: -6px;
                left: 0;
                background: @main-color;
              }
            }
          }

          .t-tag {
            height: 16px;
            font-size: 14px;
            margin-top: 12px;

            div {
              display: inline-block;
              margin-left: 8px;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          .t-desc {
            font-size: 12px;
            color: #999999;
            margin-top: 5px;
          }
        }

        .hover-box {
          position: absolute;
          top: 400px;
          left: 0;
          right: 0;
          bottom: 0;
          transition: all 0.4s ease-in-out;
          opacity: 0;
          padding: 30px 20px;
          color: #ffffff;
          background: linear-gradient(316deg,
          rgba(24, 144, 255, 0.95) 2%,
          rgba(0, 71, 193, 0.95) 96%);

          .h-title {
            font-size: 24px;
            font-weight: bolder;
          }

          .h-project {
            margin-top: 7px;
            font-size: 14px;
          }

          .h-tag {
            font-size: 14px;
            margin-top: 6px;
            padding-bottom: 12px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            div {
              display: inline-block;
              margin-left: 8px;

              &:first-child {
                margin-left: 0;
              }
            }
          }

          .h-desc {
            margin-top: 10px;
            font-size: 14px;
            line-height: 21px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
</style>
