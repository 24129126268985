<!--
 * @Description:  推荐直播
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:11:21
 * @LastEditors: hanyu
 * @LastEditTime: 2021-01-04 15:14:38
-->
<template>
  <div class="section" v-if="liveList.length > 0">
    <div class="section-title">
      <div class="title-box">
        <div class="main-title section-header">
          <p>精选直播课</p>
        </div>
      </div>
      <div class="more" >看直播 记要点 与名师近距离接触></div>
    </div>
    <div class="live-section">
      <template >
        <div class="live-fl">
          <img
            class="lecturer-pic"
            :src="activeData.teacherImg"
          >
          <div class="mask-cover" />
          <div class="live-fl-upper">
            <div class="current-live-title">
              {{ activeData.channelTitle }}
            </div>
            <div
              class="current-live-button"
              @click="executeClick(activeData)"
            >
              进入直播
            </div>
          </div>
          <div class="live-fl-lower">
            <Avatar
              class="lecturer-avatar"
              size="36"
              :src="activeData.teacherAvatar"
            />
            <p class="lecturer-name">
              {{ activeData.teacherName }}
            </p>
            <!-- nuxt-link :to="`/course/detail?courseAttribute=${COURSE_ATTRIBUTE_ENUM.LIVE.value}&courseId=${activeData.courseId}`" 暂时关闭跳转 -->
            <p class="lecturer-tit">
              来自课程：{{ activeData.courseName }}
              <Icon
                type="ios-arrow-forward"
                size="16"
              />
            </p>
          </div>
        </div>
        <div class="live-fr scroll-bar-style">
          <ul class="live-list">
            <li
              v-for="(item,index) in liveList"
              :key="index"
              :class="{active: index === activeIndex}"
            >
              <div
                class="live-item"
                :class="{playing: item.liveStatus === COURSE_LIVE_TYPE_ENUM.LIVING.value}"
                @mouseenter="enterLive(index)"
                @click="executeClick(item)"
              >
                <div class="live-time">
                  {{ item.liveStatus === COURSE_LIVE_TYPE_ENUM.LIVING.value ? '直播中' : formatTime(item.liveStartTime, item.liveEndTime) }}
                </div>
                <div class="live-name">
                  {{ item.channelTitle }}
                </div>
                <div class="live-lecturer">
                  {{ item.teacherName }}
                </div>
                <div
                  class="live-state"
                >
                <!-- :class="{hide: item.liveStatus === COURSE_LIVE_TYPE_ENUM.LIVING.value}" -->
                  {{ $enum.getDescByValue('COURSE_LIVE_TYPE_ENUM',item.liveStatus) }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <Spin
        v-if="isLoading"
        fix
      >
        <Icon
          type="ios-loading"
          size="18"
          class="demo-spin-icon-load"
        />
      </Spin>
      <!-- <Popup :visible="popupVisible" :content="popupContent" @close="closePopup" /> -->
    <div v-if="visible" class="popup">
      <!-- 弹窗的内容 -->
      <div class="popup-content">
        <div
        v-for="(item,key) in PopContent"
        :key="key"
        @click="PoprouterClick(item.courseId)"
        class="popup-content-list"
        >
        {{ item.courseName }}
        </div>
        <p class="close-button"  @click="closePopup()">X</p>
      </div>
      <!-- 关闭按钮 -->
    </div>
    </div>
  </div>
</template>

<script>
import { COURSE_LIVE_TYPE_ENUM, COURSE_ATTRIBUTE_ENUM } from '@/constant/course';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import { courseApi } from '@/api/course';
export default {
  name: 'LiveSection',
  components: { },
  filters: {
  },
  props: {

  },
  data () {
    return {
      COURSE_LIVE_TYPE_ENUM,
      COURSE_ATTRIBUTE_ENUM,
      isLoading: false,
      liveList: [],
      activeIndex: 0,
      activeData: {},
      visible: false,
      PopContent: []
    };
  },
  computed: {
  },
  watch: {},
  created () {
  },
  mounted () {
    this.queryIndexLive();
  },
  methods: {
    // 关闭弹窗
    closePopup() {
      this.visible = false
    },
    // 查询精选直播课
    async queryIndexLive () {
      const data = { "pageNum": 1,"pageSize": 10} 
      try {
        this.isLoading = true;
        const liveResult = await courseApi.queryIndexLive(data);
        // 课程名为空 不渲染
        this.liveList = liveResult.data.list.filter(function(e) {
          return e.courseName != null;
        });
        console.log(this.liveList)
        if (this.liveList.length > 0) {
          this.activeData = this.liveList[0];
        }
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 格式化直播时间
    formatTime (liveStartTime, liveEndTime) {
      // 拆分开始时间为数组
      const startTimeArr = liveStartTime.replace(' ', ':').replace(/:/g, '-').split('-');
      const liveMonth = startTimeArr[1]; // 开始月
      const liveDay = startTimeArr[2]; // 开始日
      const startHour = startTimeArr[3]; // 开始时
      const startMinute = startTimeArr[4]; // 开始分
      // 拆分结束时间为数组
      const endTimeArr = liveEndTime.replace(' ', ':').replace(/:/g, '-').split('-');
      const endHour = endTimeArr[3]; // 结束时
      const endMinute = endTimeArr[4]; // 结束分
      return `${liveMonth}-${liveDay} ${startHour}:${startMinute}-${endHour}:${endMinute}`;
    },

    // 鼠标移入列表项
    enterLive (index) {
      if (index != this.activeIndex) {
        this.activeIndex = index;
        this.activeData = this.liveList[index];
      }
    },

    // 点击某个列表项或左侧进入直播按钮
    async executeClick (item) {
      try {
        this.isLoading = true;

        // 判断是否登录
        const userInfo = this.$store.state.user.userInfo;
        if (!userInfo) {
          const params = {
            isShow: true,
            showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
          };
          this.$store.commit('user/changeAccountModal', params);
          return;
        }
        // 查询资源详情，判断是否购买{ resourceId }
        const courseIdList = { courseIdList: item.courseIdList }
        const buyFlagResult = await courseApi.queryLiveResourceDetail(courseIdList);
        console.log(buyFlagResult)
        this.PopContent = buyFlagResult.data;
        if (buyFlagResult.data == null) {
          const channelId = item.channelId;
          // 查询保利威播放url
          const playUrlResult = await courseApi.queryLivePlayUrl({ channelId });
          console.log(playUrlResult.data.playUrl)
          const tempWindow = window.open('_blank');
          tempWindow.location = playUrlResult.data.playUrl;
        } else if (buyFlagResult.data.length == 1) {
          this.PoprouterClick(buyFlagResult.data[0].courseId);
        } else {
          this.visible = true;
        }
        // if (buyFlagResult.data != null ) {
        //   this.visible = true;
        //   return
        // } else if (buyFlagResult.data.length == 1) {
        //   // 跳转课程详情
        //   this.PoprouterClick(buyFlagResult.data[0].courseId);
        //   return
        // }
        //   const channelId = item.channelId;
        //   // 查询保利威播放url
        //   const playUrlResult = await courseApi.queryLivePlayUrl({ channelId });
        //   console.log(playUrlResult.data.playUrl)
        //   const tempWindow = window.open('_blank');
        //   tempWindow.location = playUrlResult.data.playUrl;
        // console.log(item.channelId)
        // this.$router.push({
        // path: `/course/detail?courseAttribute=${COURSE_ATTRIBUTE_ENUM.LIVE.value}&courseId=${}`
        // });
        // if (!buyFlagResult.data.buyFlag) {
        //   this.$Message.warning('您暂未购买此课程');
        //   this.$router.push({
        //     path: `/course/detail?courseAttribute=${COURSE_ATTRIBUTE_ENUM.LIVE.value}&courseId=${courseId}`
        //   });
        //   return;
        // }
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 跳转课程详情
    PoprouterClick (e) {
      this.$router.push({
        path: `/course/detail?courseAttribute=${COURSE_ATTRIBUTE_ENUM.LIVE.value}&courseId=${e}`
      });
    }
  }
};
</script>
<style lang='less' scoped>
@import '../section.less';
.section{
  width: 1200px;
  margin: auto;
  padding-top: 20px;
}
.live-section {
  width: 1180px;
  margin: auto;
  display: flex;
  height: 288px;
  background-color: #fff;
  position: relative;
  // 弹窗
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .popup-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .popup-content-list{
      min-width: 80px;
      margin: 4px 6px;
      padding: 4px 6px;
      text-align: center;
      border-radius: 13px;
      border: 1px solid black;
    }
    .popup-content-list:hover{
      color: #1890ff;
      border: 1px solid #1890ff;
    }
  }
  .close-button{
    position: absolute;
    top: 4px;
    right: 4px;
  }
  }
  .live-fl {
    width: 512px;
    height: 288px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url("~@/assets/image/index/live-bg.png") center no-repeat;
    overflow: hidden;
    border-radius:4px;
    .lecturer-pic {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      width: 340px;
    }
    .mask-cover {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .live-fl-upper {
      flex: 1;
      padding: 70px 10px 0;
      position: relative;
      z-index: 3;
      .current-live-title {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 20px;
      }
      .current-live-button {
        width: 106px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #707070;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .live-fl-lower {
      height: 54px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      position: relative;
      z-index: 3;
      .lecturer-avatar {
        min-width: 36px;
        min-height: 36px;
        display: block;
        background-color: #fff;
      }
      .lecturer-name {
        flex: 1;
        min-width: 50px;
        color: #fff;
        overflow: hidden;
        margin: 0 8px;
      }
      .lecturer-tit {
        color: #fff;
        overflow: hidden;
        margin: 0 8px;
      }
      a {
        display: block;
        color: #fff;
      }
    }
  }
  .live-fr {
    flex: 1;
    height: 288px;
    overflow: hidden;
    overflow-y: auto;
    .live-list {
      position: relative;
      li {
        height: 48px;
        position: relative;
        padding: 10px 20px 10px 30px;
        &::before {
          position: absolute;
          left: 37px;
          top: 24px;
          width: 2px;
          height: 48px;
          background-color: #ededed;
          content: "";
        }
        &:last-child::before {
          display: none;
        }
        &.active {
          &::after {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            border-radius: 2px;
            background-color: @main-color;
            content: "";
          }
          .live-item {
            &:not(.playing) {
              background-image: url("~@/assets/image/index/live-state-icon-active.png");
            }
            > div:not(.live-state) {
              color: @main-color;
            }
          }
        }
        .live-item {
          height: 28px;
          padding-left: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          background: url("~@/assets/image/index/live-state-icon.png") 2px
            center no-repeat;
          background-size: 12px;
          &.playing {
            background: url("~@/assets/image/index/live-state-icon-playing.gif")
              left center no-repeat;
            background-size: 16px;
            .live-time {
              color: @main-color;
            }
          }
          .live-time {
            width: 170px;
            color: #999;
          }
          .live-name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
          }
          .live-lecturer {
            width: 76px;
            color: #999;
          }
        }
        .live-state {
          width: 52px;
          height: 28px;
          line-height: 26px;
          text-align: center;
          border: 1px solid #ededed;
          background-color: #f7f7f7;
          border-radius: 4px;
          color: #666;
          font-size: 12px;
          &.hide {
            visibility: hidden;
          }
        }
      }
    }
  }
}
</style>
